/* Type imports ------------------------------------------------------------- */
import type { ChipColor } from 'types/ChipColor'
import { StatutPaiement } from 'API/__generated__/Api'

/* Helper function declarations --------------------------------------------- */
export const getPaymentStatusColor = (statut: StatutPaiement): ChipColor => {
  switch (statut) {
    case StatutPaiement.Passe:
      return 'blue'
    case StatutPaiement.Annule:
    case StatutPaiement.Echoue:
      return 'red'
    default:
      return 'grey'
  }
}

export const periodList = [
  {
    value: new Date('2000-01-01').toLocaleString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
    label: 'Tout',
  },
  {
    value: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toLocaleString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
    label: '7 derniers jours',
  },
  {
    value: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toLocaleString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
    label: '30 derniers jours',
  },
  {
    value: new Date(Date.now() - 93 * 24 * 60 * 60 * 1000).toLocaleString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
    label: '3 derniers mois',
  },
  {
    value: new Date(Date.now() - 365 * 24 * 60 * 60 * 1000).toLocaleString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
    label: "L'année passée",
  },
]
