/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import CaseWorkflowStyledComponents from '../CaseFollowUpComponents/CaseFollowUpStyledComponents'
import CaseWorkflowJalonText from '../CaseFollowUpComponents/CaseFollowUpJalonText'

/* Type imports ------------------------------------------------------------- */
import type { JalonIntervention } from 'API/__generated__/Api'

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowInterventionJalonCardProps {
  jalon: JalonIntervention;
}

const CaseWorkflowInterventionJalonCard: React.FC<CaseWorkflowInterventionJalonCardProps> = ({ jalon }) => {

  return (
    <Card>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Title>
          Intervention d'urgence et mise en sécurité
        </CaseWorkflowStyledComponents.Title>
        <div />
        <CaseWorkflowJalonText
          jalon={jalon.type.code}
          date={jalon.intervention?.dateDebut || ''}
          interventionDetails={jalon.intervention ?? undefined}
          state={jalon.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
    </Card>
  )
}

export default CaseWorkflowInterventionJalonCard
