/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { getPaymentStatusColor } from 'helpers/caseUtils'
import DateUtils from 'helpers/DateUtils'
import { formatPrice } from 'helpers/numberUtils'

/* Component imports -------------------------------------------------------- */
import { Dialog } from '@mui/material'
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'
import Table from 'components/Table/Table'
import CloseButton from 'components/CloseButton/CloseButton'
import CaseWorkflowStyledComponents from '../CaseFollowUpStyledComponents'

/* Type imports ------------------------------------------------------------- */
import type { ColumnHeader } from 'types/Table'
import type {
  Paiement,
  StatutPaiementEnumLabel, 
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const StatusChipContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  .status-chip {
    font-size: .8rem;
  }
`

const StyledContainer = styled.div`
  min-width: 600px;
`

interface CaseFollowUpInstallmentsTableButtonProps {
  installments: Paiement[];
}

const headers: ColumnHeader[] = [
  {
    id: 'id',
    label: 'Numéro',
  },
  {
    id: 'statutPaiement',
    label: 'Statut',
    render: (status: StatutPaiementEnumLabel) => (
      <StatusChipContainer>
        <ColoredSquareChip
          color={getPaymentStatusColor(status.code)}
          className="status-chip"
        >
          {status.libelle}
        </ColoredSquareChip>
      </StatusChipContainer>
    ),
  },
  {
    id: 'montant',
    label: 'Montant',
    align: 'right',
    render: (amount: number) => formatPrice(amount),
  },
  {
    id: 'dateModification',
    label: 'Effectué le',
    render: (date: string) => DateUtils.APIStrToLocalDateString(date),
  },
]

const CaseFollowUpInstallmentsTableButton: React.FC<CaseFollowUpInstallmentsTableButtonProps> = ({ installments }) => {
  const [ open, setOpen ] = useState<boolean>(false)

  const onClick = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <>
      <CaseWorkflowStyledComponents.Button
        variant="outlined"
        color="warning"
        onClick={onClick}
      >
        Afficher la table des échéances
      </CaseWorkflowStyledComponents.Button>
      {
        open &&
          <Dialog
            open
            onClose={onClose}
            maxWidth={false}
          >
            <CaseWorkflowStyledComponents.DialogTitle>
              Table des échéances
              <CloseButton handleClose={onClose} />
            </CaseWorkflowStyledComponents.DialogTitle>
            <CaseWorkflowStyledComponents.DialogContent>
              <StyledContainer>
                <Table
                  colHeaders={headers}
                  rows={installments}
                  setRows={() => []}
                />
              </StyledContainer>
            </CaseWorkflowStyledComponents.DialogContent>
          </Dialog>
      }
    </>
  )
}

export default CaseFollowUpInstallmentsTableButton
