/* Framework imports -------------------------------------------------------- */
import React, { useEffect } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useParams, 
} from 'react-router-dom'

/* Component imports -------------------------------------------------------- */
import { CircularProgress } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import RouteTitle from 'router/RouteTitle'
import BaseLayout from 'layouts/BaseLayout/BaseLayout'
import AuthContainer from 'components/AuthComponents/AuthContainer'
import AuthWelcomeMessage from 'components/AuthComponents/AuthWelcomeMessage'
import AuthErrorMessage from 'components/AuthComponents/AuthErrorMessage'

/* Styled components -------------------------------------------------------- */
const Message = styled.div`
    margin-top: 2rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  
    @media ${(props) => props.theme.media.mobile.main} {
      margin-top: 10px;
    }
  `

const StyledErrorMessage = styled(AuthErrorMessage)`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap:10px;
  align-items: center;
`
const StyledErrorIcon = styled(CancelIcon) `
  font-size:5.2rem;
`
const StyledCheckIcon = styled(CheckCircleOutlineOutlinedIcon) `
  font-size:5.2rem;
`

/* Component declaration ---------------------------------------------------- */
interface PaymentResultPageProps {}

const PaymentResultPage: React.FC<PaymentResultPageProps> = () => {
  const { result } = useParams<{ result: string }>()
  const navigate = useNavigate()

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/mon-dossier')
    }, 5000)
    return () => clearTimeout(timer)
  }, [])

  return (
    <BaseLayout className="AuthLayout">
      <RouteTitle title="Paiement" />
      <AuthContainer>
        <AuthWelcomeMessage>
          l'Extranet Assuré iREN
        </AuthWelcomeMessage>
        <Message>
          {
            result?.toLocaleLowerCase() === 'success' ? 
              (
                <Message>
                  <StyledCheckIcon />
                  Votre paiement a été traité avec succès. Nous vous remercions pour votre confiance.
                </Message>
              ):
              (
                <StyledErrorMessage>
                  <StyledErrorIcon />
                  Nous n'avons pas pu traiter votre paiement. Veuillez vérifier vos informations bancaires et réessayer, ou contacter votre gestionnaire pour plus d'assistance.
                </StyledErrorMessage>
              )
          }
          <br />
          Veuillez patienter pendant que nous vous redirigeons...
          <CircularProgress />
        </Message>
      </AuthContainer>
    </BaseLayout>
  )
}

export default PaymentResultPage
