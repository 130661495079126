/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Module imports ----------------------------------------------------------- */
import { useNavigate } from 'react-router-dom'
import { getCleanFilename } from 'helpers/mediaUtils'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import CaseFollowUpStyledComponents from './CaseFollowUpStyledComponents'
import CaseFollowUpDownloadButton from './CaseFollowUpButtons/CaseFollowUpDownloadButton'
import CaseFollowUpJalonText from './CaseFollowUpJalonText'
import CaseFollowUpRateButton from './CaseFollowUpRateButton'

/* Type imports ------------------------------------------------------------- */
import type {
  ExpAssure,
  JalonSimplifie, 
} from 'API/__generated__/Api'
import {
  EtatFacture,
  WorkflowJalon,
  EtatDevis,
  WorkflowEtat,
} from 'API/__generated__/Api'

/* Component declaration ---------------------------------------------------- */
interface CaseFollowUpHistoryCardProps {
  jalon: JalonSimplifie;
  quoteState?: EtatDevis;
  invoiceState?: EtatFacture;
  isUrgentCase?: boolean;
  comment?: string;
  notes?: ExpAssure[] | null;
  ratingComment?: string | null;
}

const CaseFollowUpHistoryCard: React.FC<CaseFollowUpHistoryCardProps> = ({
  jalon,
  quoteState,
  invoiceState,
  comment,
  notes,
  ratingComment,
}) => {
  const navigate = useNavigate()

  const getButton = () => {
    switch (jalon.type.code) {
      case WorkflowJalon.AttestationTva:
        break
      case WorkflowJalon.Devis:
        if (quoteState) {
          if (quoteState === EtatDevis.Transmis || quoteState === EtatDevis.Retransmis) {
            return (
              <CaseFollowUpStyledComponents.Button
                variant="contained"
                onClick={() => navigate('devis')}
              >
                Voir et accepter le Devis
              </CaseFollowUpStyledComponents.Button>
            )
          }
        }
        break
      case WorkflowJalon.Facture:
        if (invoiceState) {
          if (invoiceState === EtatFacture.Envoye) {
            return (
              <CaseFollowUpStyledComponents.Button
                variant="contained"
                onClick={() => navigate('facture')}
              >
                Voir la facture
              </CaseFollowUpStyledComponents.Button>
            )
          }
        }
        break
      case WorkflowJalon.DossierTermine:
        if (jalon.etat === WorkflowEtat.NonFait) return
        return (
          <CaseFollowUpRateButton
            notesData={notes || []}
            comment={ratingComment || ''}
          />
        )
      default:
        break
    }
    if (jalon.pieceJointe) {
      return (
        <CaseFollowUpDownloadButton
          name={getCleanFilename(jalon.pieceJointe?.libelle || '', jalon.pieceJointe?.fileName || '')}
          url={jalon.pieceJointe?.url || ''}
        />
      )
    }
  }
  return (
    <Card>
      <CaseFollowUpStyledComponents.CardContent>
        <CaseFollowUpStyledComponents.Title>
          {jalon.libelle ?? jalon.type.libelle}
        </CaseFollowUpStyledComponents.Title>
        {getButton() ?? <div />}
        <CaseFollowUpJalonText
          jalon={jalon.type.code}
          date={jalon.date}
          state={jalon.etat}
          quoteState={quoteState}
          invoiceState={invoiceState}
          comment={comment}
        />
      </CaseFollowUpStyledComponents.CardContent>
    </Card>
  )
}

export default CaseFollowUpHistoryCard
