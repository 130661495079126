/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useLocation,
} from 'react-router-dom'
import {
  usePatchQuoteStateMutation,
  useGetCaseHistoryListQuery,
} from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import { CircularProgress } from '@mui/material'
import { toast } from 'react-toastify'
import Footer from 'layouts/Footer/Footer'
import CaseWorkflowRefuseDevisButton from 'pages/CaseFollowUpPage/CaseFollowUpComponents/CaseFollowUpButtons/CaseFollowUpRefuseQuoteButton'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import LongButton from 'components/LongButton/LongButton'
import BackButton from 'components/BackButton/BackButton'

/* Type imports ------------------------------------------------------------- */
import { StatutDevis } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const DuoButtonContainer = styled.div`
  display: flex;
  gap: 10px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    flex-direction: column-reverse;
  }
`

interface Location {
  caseId: string;
}
/* Component declaration ---------------------------------------------------- */
interface QuotePageProps {}

const QuotePage: React.FC<QuotePageProps> = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const locationParams = location.state as Location | undefined
  const {
    currentData: workflow,
    isFetching: isFetchingWorkflow,
  } = useGetCaseHistoryListQuery(locationParams?.caseId || '')
  const [
    changeQuoteState,
    { isLoading: isSendingQuoteState },
  ] = usePatchQuoteStateMutation()

  const onClickChangeQuoteState = (accept: boolean) => {
    changeQuoteState({ quoteId: workflow?.jalonDevis?.devis?.id || '', data: { statut: accept ? StatutDevis.Valide : StatutDevis.Annule }})
      .then((response) => {
        if (isApiError(response)) {
          toast.error(`Une erreur est survenue lors ${accept ? 'de la validation' : 'du refus'} du devis.`)
        } else if (response) {
          toast.success(`Le devis à bien été ${accept ? 'validé' : 'refusé'}.`)
          navigate('/mon-dossier')
        }
      })
      .catch(console.error)
  }

  return (
    <div>
      <BackButton onClick={() => navigate('/mon-dossier')}>
        Retourner au suivi
      </BackButton>
      <LargeTitle>
        Devis
        <DuoButtonContainer>
          <CaseWorkflowRefuseDevisButton
            caseId={locationParams?.caseId || ''}
            quoteId={workflow?.jalonDevis?.devis?.id || ''}
          />
          <LongButton
            variant="contained"
            onClick={() => onClickChangeQuoteState(true)}
            disabled={isSendingQuoteState}
          >
            Valider
          </LongButton>
        </DuoButtonContainer>
      </LargeTitle>
      {
        isFetchingWorkflow ?
          <CircularProgress /> :
          workflow?.jalonDevis?.pieceJointe &&
            <div>
              <iframe
                src={workflow.jalonDevis.pieceJointe?.url || ''}
                width="100%"
                height="700px"
                title={workflow.jalonDevis.pieceJointe?.libelle ?? 'Devis'}
              />
            </div>
      }
      <Footer />
    </div>
  )
}

export default QuotePage
