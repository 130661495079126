/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Module imports ----------------------------------------------------------- */
import {
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'
import { useAuthInfo } from 'store/hooks'

/* Component imports -------------------------------------------------------- */
// Layouts
import AuthLayout from 'layouts/AuthLayout/AuthLayout'
import MainLayout from 'layouts/MainLayout/MainLayout'
// Main Pages
import ExternalLoginPage from 'pages/AuthPages/ExternalLoginPage/ExternalLoginPage'
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage'
import CaseFollowUpPage from 'pages/CaseFollowUpPage/CaseFollowUpPage'
import MessagesPage from 'pages/MessagesPage/MessagesPage'
import QuotePage from 'pages/QuotePage/QuotePage'
import PaymentResultPage from 'pages/PaymentPages/PaymentResultPage'

/* Component declaration ---------------------------------------------------- */
interface AuthRouterProps {}

const Router: React.FC<AuthRouterProps> = () => {
  const authInfo = useAuthInfo()

  console.log('[DEBUG] authInfo :', authInfo)

  return (
    <Routes>
      <Route
        path="/"
        element={
          authInfo !== null ?
            <MainLayout /> :
            <Navigate
              to="/connexion-automatique/null"
              replace
            />
        }
      >          
        <Route
          path="mon-dossier"
        >
          <Route
            index
            element={<CaseFollowUpPage />}
          />
          <Route
            path="devis"
            element={<QuotePage />}
          />
        </Route>
        <Route
          path="messagerie"
          element={<MessagesPage />}
        />
        <Route
          index
          element={
            <Navigate
              to="/mon-dossier"
              replace
            />
          }
        />
        <Route
          path="resultat-paiement/:result"
          element={<PaymentResultPage />}
        />
        <Route
          path="*"
          element={<NotFoundPage />}
        />
      </Route>
      <Route element={<AuthLayout />}>
        <Route
          path="/connexion-automatique/:caseRef/*"
          element={<ExternalLoginPage />}
        />
      </Route>
      <Route
        path="*"
        element={<NotFoundPage />}
      />
    </Routes>
  )
}

export default Router
