/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import CaseWorkflowJalonText from '../CaseFollowUpComponents/CaseFollowUpJalonText'
import CaseWorkflowStyledComponents from '../CaseFollowUpComponents/CaseFollowUpStyledComponents'
import CaseFollowUpInstallmentsTableButton from '../CaseFollowUpComponents/CaseFollowUpButtons/CaseFollowUpInstallmentsTableButton'

/* Type imports ------------------------------------------------------------- */
import {
  TypePaiement,
  type JalonPaiement, 
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const StyledDueContainer = styled(CaseWorkflowStyledComponents.DuoButtonsContainer) `
  grid-template-columns: auto;
`

/* Component declaration ---------------------------------------------------- */
interface CaseFollowUpPaiementJalonCardProps {
  jalon: JalonPaiement;
}

const CaseFollowUpPaiementJalonCard: React.FC<CaseFollowUpPaiementJalonCardProps> = ({ jalon }) => {
  return (
    <Card>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Line>
          Pré-paiement
        </CaseWorkflowStyledComponents.Line>
        <StyledDueContainer>
          {
            jalon.lienPaiement &&
            (
              <CaseWorkflowStyledComponents.Button
                variant="contained"
                onClick={() => window.location.replace(jalon.lienPaiement || '#')}
              >
                Je paie le montant des travaux
              </CaseWorkflowStyledComponents.Button>)
          }
          {
            jalon.echeances && jalon.echeances.length > 0 && jalon.typePaiement.code === TypePaiement.Echeance && 
              <CaseFollowUpInstallmentsTableButton installments={jalon.echeances} />
          }
        </StyledDueContainer>
        <CaseWorkflowJalonText
          jalon={jalon.type.code}
          date={jalon.effectueLe || ''}
          state={jalon.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
    </Card>
  )
}

export default CaseFollowUpPaiementJalonCard
