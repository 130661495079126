/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Component imports -------------------------------------------------------- */
import { Rating as MuiRating } from '@mui/material'
import { StarRounded } from '@mui/icons-material'

/* Component declaration ---------------------------------------------------- */
interface RatingProps {
  value: number | null;
  onChange: (value: number | null) => void;
  size?: 'small' | 'large' | 'medium';
  disabled?: boolean;
}

const Rating: React.FC<RatingProps> = ({ value, onChange, size='large', disabled = false }) => {

  return (
    <MuiRating
      value={value}
      disabled={disabled}
      size={size}
      icon={
        <StarRounded
          color="primary"
          fontSize="inherit"
        />
      }
      emptyIcon={
        <StarRounded
          color="info"
          fontSize="inherit"
        />
      }
      onChange={(event, newValue) => onChange(newValue)}
    />
  )
}

export default Rating
