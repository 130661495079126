/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Module imports ----------------------------------------------------------- */
import { getCleanFilename } from 'helpers/mediaUtils'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import CaseWorkflowDot from '../CaseFollowUpComponents/CaseFollowUpDot'
import CaseWorkflowStyledComponents from '../CaseFollowUpComponents/CaseFollowUpStyledComponents'
import CaseWorkflowDownloadButton from '../CaseFollowUpComponents/CaseFollowUpButtons/CaseFollowUpDownloadButton'
import CaseWorkflowJalonText from '../CaseFollowUpComponents/CaseFollowUpJalonText'

/* Type imports ------------------------------------------------------------- */
import type { JalonFinDeTravauxRDF } from 'API/__generated__/Api'

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowWaterWorkDoneJalonCardProps {
  jalon: JalonFinDeTravauxRDF;
}

const CaseWorkflowWaterWorkDoneJalonCard: React.FC<CaseWorkflowWaterWorkDoneJalonCardProps> = ({ jalon }) => {
  return (
    <Card>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Title>
          Fin de travaux RDF
        </CaseWorkflowStyledComponents.Title>
        <div />
        <CaseWorkflowJalonText
          jalon={jalon.type.code}
          date={jalon.dateValidation || ''}
          state={jalon.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
      {
        jalon.sousJalonRapportRechercheDeFuite &&
          <CaseWorkflowStyledComponents.CardContent>
            <CaseWorkflowStyledComponents.Line>
              <CaseWorkflowDot
                state={jalon.sousJalonRapportRechercheDeFuite.etat}
                small
              />
              Rapport de recherche de fuite
            </CaseWorkflowStyledComponents.Line>
            {
              jalon.sousJalonRapportRechercheDeFuite.rapportRechercheDeFuite ?
                <CaseWorkflowDownloadButton
                  name={getCleanFilename(jalon.sousJalonRapportRechercheDeFuite.rapportRechercheDeFuite.libelle || '', jalon.sousJalonRapportRechercheDeFuite.rapportRechercheDeFuite.nomDuFichier || '')}
                  url={jalon.sousJalonRapportRechercheDeFuite.rapportRechercheDeFuite.url || ''}
                /> :
                <div />
            }
            <CaseWorkflowJalonText
              jalon="sousJalonRapportRechercheDeFuite"
              date={jalon.sousJalonRapportRechercheDeFuite.dateValidation || ''}
              state={jalon.sousJalonRapportRechercheDeFuite.etat}
            />
          </CaseWorkflowStyledComponents.CardContent>
      }
    </Card>
  )
}

export default CaseWorkflowWaterWorkDoneJalonCard
