/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import { WarningAmberRounded } from '@mui/icons-material'
import CaseFollowUpStyledComponents from '../CaseFollowUpComponents/CaseFollowUpStyledComponents'
import CaseFollowUpIssueJalonText from '../CaseFollowUpComponents/CaseFollowUpIssueJalonText'

/* Type imports ------------------------------------------------------------- */
import type { Anomalie } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const IssueTitleContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  color: ${(props) => props.theme.palette.warning.main};
`

/* Component declaration ---------------------------------------------------- */
interface CaseFollowUpIssueJalonCardProps {
  issue: Anomalie;
}

const CaseFollowUpIssueJalonCard: React.FC<CaseFollowUpIssueJalonCardProps> = ({ issue }) => {

  return (
    <Card>
      <CaseFollowUpStyledComponents.CardContent>
        <CaseFollowUpStyledComponents.Title>
          <IssueTitleContainer>
            <WarningAmberRounded color="warning" />
            {issue.anomalieType.nom}
          </IssueTitleContainer>
        </CaseFollowUpStyledComponents.Title>
        <div />
        <CaseFollowUpIssueJalonText
          state={issue.statut}
          endDate={issue.dateFin || ''}
          startDate={issue.dateDebut}
        />
      </CaseFollowUpStyledComponents.CardContent>
    </Card>
  )
}

export default CaseFollowUpIssueJalonCard
